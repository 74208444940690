import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import { splitProps, type ComponentProps } from 'solid-js';
import { Portal } from 'solid-js/web';
import { cn } from '~/ui/methods/classNames';

const toastVariants = cva('toast', {
  variants: {
    verticalAlignment: {
      top: 'toast-top',
      middle: 'toast-middle',
      bottom: 'toast-bottom',
    },
    horizontalAlignment: {
      start: 'toast-start',
      center: 'toast-center',
      end: 'toast-end',
    },
  },
});
export interface ToastProps
  extends VariantProps<typeof toastVariants>,
    ComponentProps<'div'> {}
export const Toast = (props: ToastProps) => {
  const [, otherProps] = splitProps(props, [
    'verticalAlignment',
    'horizontalAlignment',
  ]);
  return (
    <Portal>
      <div
        {...otherProps}
        class={cn(
          toastVariants({
            verticalAlignment: props.verticalAlignment,
            horizontalAlignment: props.horizontalAlignment,
          }),
          props.class,
        )}
      >
        {props.children}
      </div>
    </Portal>
  );
};
