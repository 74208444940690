import { FontAwesomeIcon } from '~/ui/components/icons/FontAwesomeIcon';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons/faCircleInfo';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation';
import { cn } from '~/ui/methods/classNames';
import { Show, type ComponentProps } from 'solid-js';
import type { VariantProps } from 'class-variance-authority';
import { cva } from 'class-variance-authority';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Button from '~/ui/components/actions/Button';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { splitProps } from 'solid-js';

const alertVariants = cva('alert', {
  variants: {
    type: {
      default: 'alert-default',
      info: 'alert-info',
      success: 'alert-success',
      warning: 'alert-warning',
      error: 'alert-error',
    },
  },
  defaultVariants: {
    type: 'default',
  },
});

export interface AlertProps
  extends Omit<ComponentProps<'div'>, 'color'>,
    VariantProps<typeof alertVariants> {
  icon?: IconDefinition;
  handleClose?: (e: MouseEvent) => void;
}

const ICON_MAP = {
  default: faCircleInfo,
  info: faCircleInfo,
  success: faCircleCheck,
  warning: faTriangleExclamation,
  error: faCircleExclamation,
};

export const Alert = (props: AlertProps) => {
  const icon = props.icon ?? ICON_MAP[props.type ?? 'default'];
  const [, otherProps] = splitProps(props, ['icon', 'handleClose', 'type']);

  return (
    <div
      role='alert'
      class={cn(alertVariants({ type: props.type }), props.class)}
      {...otherProps}
    >
      <FontAwesomeIcon icon={icon} class='fill-current w-4 aspect-square' />
      <span>{props.children}</span>
      <Show when={props.handleClose}>
        <Button
          class={'self-start'}
          round
          variant={'link'}
          size={'xs'}
          onClick={props.handleClose}
        >
          <FontAwesomeIcon icon={faXmark} class={'w-3'} />
        </Button>
      </Show>
    </div>
  );
};
