import { createEffect, createSignal, For } from 'solid-js';
import { removeToast, toasterStore } from '~/common/stores/toasterStore';
import { Toast } from '~/ui/components/feedback/Toast';
import { Alert } from '~/ui/components/feedback/Alert';
import { awaitAnimations } from '~/common/methods/awaitAnimations';

export const Toaster = () => {
  const timeouts: Record<string, NodeJS.Timeout | boolean> = {};
  const [toasterRef, setToasterRef] = createSignal<HTMLDivElement>();
  const closeToast = async (id: string, ref: HTMLDivElement) => {
    const targetSize = ref?.getBoundingClientRect();
    const alerts = (toasterRef()?.querySelectorAll('.alert') ||
      []) as HTMLDivElement[];

    ref?.classList.add('xyz-out');
    ref?.classList.remove('xyz-in', 'h-auto');
    await awaitAnimations([ref]);
    let isTargetSeen = false;
    for (const alert of alerts) {
      if (ref === alert) {
        isTargetSeen = true;
        continue;
      }
      if (!isTargetSeen) {
        continue;
      }
      alert.classList.add('transition-all');
      alert.style.transform = `translateY(-${targetSize.height + 10}px)`;
    }
    await awaitAnimations(alerts);
    for (const alert of alerts) {
      alert.classList.remove('transition-all');
      alert.style.transform = ``;
    }
    removeToast(id);
  };
  const createHandleClose = (id: string) => async (evt: MouseEvent) => {
    const target = (evt.target as HTMLDivElement)?.closest<HTMLDivElement>(
      '.alert',
    );
    if (!target) {
      return;
    }
    closeToast(id, target);
  };

  createEffect(() => {
    for (const [id, toast] of Object.entries(toasterStore())) {
      if (timeouts[id]) {
        continue;
      }
      timeouts[id] = toast.duration
        ? setTimeout(() => {
            const target = toasterRef()?.querySelector<HTMLDivElement>(
              `.alert[data-id=${id}]`,
            );
            if (!target) {
              return;
            }
            closeToast(id, target);
          }, toast.duration)
        : true;
    }
  });

  return (
    <Toast
      ref={setToasterRef}
      verticalAlignment='top'
      horizontalAlignment='end'
      xyz='fade in-down in-stagger-1 out-stagger-rev-1 '
    >
      <For each={Object.values(toasterStore())}>
        {(toast) => (
          <Alert
            type={toast.type}
            handleClose={createHandleClose(toast.id)}
            data-id={toast.id}
          >
            {toast.message}
          </Alert>
        )}
      </For>
    </Toast>
  );
};
